<template>
  <div style="display:flex;">
    <div class="view-box">
      <el-image
        class="view-img-box"
        :for="name + 'uploads'"
        :src="uploadImgRelaPath"
        :style="{
          width: viewWidth + 'px',
          height: viewHeight + 'px',
          'line-height': viewHeight + 'px',
        }"
      >
        <div slot="error">
          <span>未上传照片</span>
        </div>
      </el-image>
      <div class="upload-btn" v-if="showUploadButton">
        <label class="view-img-btn" :for="name + 'uploads'">点击上传</label>
        <p class="tips-txt">
          请上传图片尺寸：{{
            remarks || "100*100"
          }}像素，<br />支持JPG、JPEG、PNG格式
        </p>
      </div>
      <input
        type="file"
        :id="name + 'uploads'"
        :value="imgFile"
        style="display:none;"
        accept="image/png, image/jpeg, image/gif, image/jpg"
        @change="uploadImg($event, 1)"
      />
    </div>
    <el-dialog
      title="图片裁剪"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
      :append-to-body="appendToBody"
    >
      <div class="info-item">
        <div class="cropper-content">
          <div class="cropper">
            <vue-cropper
              ref="cropper"
              v-bind="option"
              @realTime="realTime"
              @imgLoad="imgLoad"
            ></vue-cropper>
          </div>
        </div>
        <div class="cropper-btns">
          <el-button
            type="primary"
            icon="fa fa-plus"
            circle
            @click="changeScale(1)"
          ></el-button>
          <el-button
            type="primary"
            icon="fa fa-minus"
            circle
            @click="changeScale(-1)"
          ></el-button>
          <el-button
            type="warning"
            icon="fa fa-rotate-left"
            circle
            @click="rotateLeft"
          ></el-button>
          <el-button
            type="warning"
            icon="fa fa-rotate-right"
            circle
            @click="rotateRight"
          ></el-button>
          <el-button
            type="success"
            icon="fa fa-arrow-down"
            circle
            @click="down('blob')"
          ></el-button>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="finish('blob')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";

export default {
  name: "PictureCropper",

  components: {
    VueCropper,
  },
  // props: ['placeholder', 'label', 'name', 'value', 'width', 'height'],
  inject: {
    editUpload: {
      default: () =>
        function() {
          // console.log("不存在此函数");
        },
    },
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    remarks: {
      type: String,
      default: "100*100",
    },
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 300,
    },
    uploadPath: {
      type: String,
      default: "/File/UploadSingleFile",
    },
    showUploadButton: {
      type: Boolean,
      default: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    fullPath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      headImg: this.value,
      // 剪切图片上传
      crap: false,
      previews: {},
      option: {
        img: "", // 裁剪图片的地址
        info: false, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: "png", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // autoCropWidth: 300, // 默认生成截图框宽度
        // autoCropHeight: 225, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      fileName: "", // 本机文件地址
      downImg: "#",
      imgFile: "",
      uploadImgRelaPath: "", // 上传后的图片的地址（不带服务器域名）
      dialogVisible: false,
    };
  },
  watch: {
    pixelScale(n) {
      // // console.log('变了', n)
      this.option.fixedNumber = n;
    },
    fullPath(newVal, oldVal) {
      // console.log(oldVal);
      this.headImg = newVal;
    },
  },
  mounted() {
    // // console.log(this.remarks)
    this.option.fixedNumber = this.pixelScale;
  },
  computed: {
    pixelScale() {
      if (this.remarks && this.remarks.indexOf("*") !== -1) {
        return this.remarks
          .replace(/\s*/g, "")
          .split("*")
          .map((e) => e * 1);
      } else {
        return [1, 1];
      }
    },
    viewWidth() {
      if (this.width >= 200) {
        return 200;
      } else {
        return this.width;
      }
      // return 200
    },
    viewHeight() {
      return (this.viewWidth * this.pixelScale[1]) / this.pixelScale[0];
    },
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    cancel() {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.editUpload(false);
        })
        .catch((_) => {});
    },
    // 放大/缩小
    changeScale(num) {
      // // console.log('changeScale')
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 坐旋转
    rotateLeft() {
      // // console.log('rotateLeft')
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRight() {
      // // console.log('rotateRight')
      this.$refs.cropper.rotateRight();
    },
    // 上传图片（点击上传按钮）
    finish(type) {
      // // console.log('finish')
      this.dialogVisible = false;
      const _this = this;
      const formData = new FormData();
      // 输出
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          const img = window.URL.createObjectURL(data);
          this.model = true;
          this.modelSrc = img;
          formData.append("file", data, this.fileName);
          this.$http
            .post(this.uploadPath, formData, {
              contentType: false,
              processData: false,
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
            })
            .then((res) => {
              if (res.StatusCode === 0) {
                // $('#btn1').val('')
                _this.imgFile = "";
                _this.headImg = res.Result.FullPath; // 完整路径
                // _this.uploadImgRelaPath = res.Result.Path; // 非完整路径
                _this.uploadImgRelaPath = res.Result.FullPath; // 非完整路径
                // // console.log(this.name)
                this.$emit(
                  "input",
                  res.Result.Name,
                  res.Result.FullPath,
                  res.Result.SavePath
                );
                _this.$message.success("上传成功");
              }
              this.editUpload(false);
            })
            .catch(() => {
              this.editUpload(false);
            });
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.model = true;
          this.modelSrc = data;
        });
      }
    },
    // 实时预览函数
    realTime(data) {
      // // console.log('图片信息', data)
      this.previews = data;
    },
    // 下载图片
    down(type) {
      // // console.log('down')
      var aLink = document.createElement("a");
      aLink.download = "author-img";
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          this.downImg = window.URL.createObjectURL(data);
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.downImg = data;
          aLink.href = data;
          aLink.click();
        });
      }
    },
    // 选择本地图片
    uploadImg(e, num) {
      this.editUpload(true);
      // // console.log(this.name)
      var _this = this;
      // 上传图片
      var file = e.target.files[0];
      _this.fileName = file.name;
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          _this.option.img = data;
        } else if (num === 2) {
          _this.example2.img = data;
        }
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
      this.dialogVisible = true;
    },
    imgLoad(msg) {
      // // console.log('imgLoad')
      // // console.log(msg)
    },
  },
};
</script>

<style lang="scss">
.view-box {
  display: flex;
  align-items: center;
}
.tips-txt {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
}
.view-img-box {
  // display: inline-block;
  // vertical-align: middle;
  color: #8c939d;
  font-size: 14px;
  overflow: hidden;
  box-sizing: content-box;
  border: 1px solid #ddd;
  text-align: center;
  cursor: pointer;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: 1px solid rgba(237, 237, 237, 1);
  i {
    font-size: 30px;
    vertical-align: middle;
  }
}
.upload-btn {
  margin-left: 14px;
}
.view-img-btn {
  display: inline-block;
  text-align: center;
  color: #528cff;
  width: 80px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #528cff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transform: translateX(50%);
  margin-left: 20px;
}
.view-img {
  display: inline-block;
  height: 100%;
  width: 100%;
  color: rgba(102, 102, 102, 0.5);
}

.cropper-content {
  // display: flex;
  // display: -webkit-flex;
  // justify-content: center;
  // -webkit-justify-content: center;
  // align-items: center;
  // -webkit-align-items: center;
  .cropper {
    width: auto;
    height: 300px;
  }
  // .show-preview {
  //   // flex: 1;
  //   // -webkit-flex: 1;
  //   // display: flex;
  //   // display: -webkit-flex;
  //   // justify-content: center;
  //   // -webkit-justify-content: center;
  //   .preview {
  //     overflow: hidden;
  //     // border-radius: 50%;
  //     border: 1px solid #cccccc;
  //     background: #cccccc;
  //     margin-left: 40px;
  //   }
  // }
}
.cropper-content .show-preview .preview {
  margin-left: 0;
}
.cropper-btns {
  margin-top: 10px;
  text-align: center;
}
</style>
