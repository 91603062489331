<template>
  <div class="divContainer">
    <div v-loading="loading" class="divContent">
      <el-form
        v-if="!loading"
        ref="mainFrom"
        :model="formData"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item prop="Headline" label="标题">
          <span v-if="isView" v-text="formData.Headline"></span>
          <el-input
            v-else
            v-model="formData.Headline"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="Illustration" label="先导图">
              <el-image
                v-if="isView"
                :src="formData.Illustration"
                style="width:376px"
              ></el-image>
              <picture-selector
                v-else
                :uploadPath="'/File/UploadSingleFile'"
                @input="updateFileImg"
                name="Illustration"
                remarks="376*266"
              ></picture-selector>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="ContentType" label="所属板块">
              <span v-if="isView" v-text="formData.ContentTypeText"></span>
              <el-select
                v-else
                v-model="formData.ContentType"
                style="width:100%"
                placeholder="请选择所属板块或新闻类型"
              >
                <el-option
                  v-for="item in typeOptions"
                  :key="item.Key"
                  :label="item.Description"
                  :value="item.Key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="Summary" label="新闻摘要">
          <span v-if="isView" v-text="formData.Summary"></span>
          <el-input
            v-else
            v-model="formData.Summary"
            type="textarea"
            placeholder="请输入摘要，未输入将从正文提取前100字"
          ></el-input>
        </el-form-item>
        <el-form-item prop="Contents" label="新闻正文">
          <div v-if="isView" v-html="formData.Contents"></div>
          <rich-text
            v-else
            v-model="formData.Contents"
            name="Contents"
          ></rich-text>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="Sort" label="置顶排序">
              <span v-if="isView" v-text="formData.Sort"></span>
              <el-input
                v-else
                v-model="formData.Sort"
                type="number"
                placeholder="请输入排序优先级，数字越大排在越前面"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="ReleaseTime" label="发布时间">
              <span v-if="isView" v-text="formData.ReleaseTime"></span>
              <el-date-picker
                v-else
                v-model="formData.ReleaseTime"
                type="datetime"
                @change="datechange"
                format="yyyy-MM-dd HH:mm"
                :style="{ width: '100%' }"
                placeholder="新闻资讯显示的发布时间"
                clearable
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="devOperate">
      <slot name="buttons">
        <el-button
          v-if="!this.isView"
          :loading="isUploading"
          type="primary"
          icon="fa fa-save"
          class="btnBottom"
          @click="submitForm"
        >
          保存
        </el-button>
        <el-button
          :loading="isUploading"
          icon="fa fa-reply"
          class="btnBottom"
          @click="cancle"
        >
          {{ this.isView ? "返回" : "取消" }}
        </el-button>
      </slot>
    </div>
  </div>
</template>

<script>
import { formartDate } from "@/utils/tools";
import {
  createEntity,
  updateEntity,
  getInfo,
  getNewsTypeOptions
} from "@/api/news";
import contentTools from "@/mixins/content-tools";
import PictureSelector from "@/components/PictureSelector";
import RichText from "@/components/RichText";
export default {
  name: "BannerDetail",
  components: {
    PictureSelector,
    RichText
  },
  mixins: [contentTools],
  props: {
    isView: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isUploading: false,
      loading: false,
      id: "",
      formData: {
        Headline: "",
        Illustration: "",
        ReleaseTime: "",
        Summary: "",
        Contents: ""
      },
      rules: {
        Headline: [{ required: true, message: "请输入标题", trigger: "blur" }],
        Illustration: [
          { required: true, message: "请选择先导图", trigger: "change" }
        ],
        Contents: [
          { required: true, message: "请输入新闻下文", trigger: "blur" }
        ],
        ContentType: [
          { required: true, message: "请选择所属板块", trigger: "change" }
        ]
      },
      typeOptions: []
    };
  },
  created() {
    this.id = this.$route.query.id || "";
    this.initialize();
  },
  methods: {
    async initialize() {
      this.loading = true;
      try {
        getNewsTypeOptions().then(res => {
          this.typeOptions = res.Result;
        });
        // 查看和编辑时需要获取详情
        if (this.id) {
          const res = await getInfo(this.id);
          if (res.IsSuccess) {
            this.formData = res.Result;
          }
        } else {
          this.formData.ReleaseTime = this.getNowDate();
          // console.log("formData:", this.formData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    updateFileImg(name, file) {
      console.log(name, file);
      this.formData.Illustration = file;
    },
    submitForm() {
      console.log("postData:", this.formData);
      this.$refs.mainFrom.validate(async valid => {
        if (valid) {
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          const postData = Object.assign({}, this.formData);
          try {
            if (this.isEdit) {
              console.log("edit");
              const res = await updateEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            } else {
              const res = await createEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            }
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isUploading = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误"
          });
          return;
        }
      });
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "温馨提示"
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$router.go(-1);
      }
    },
    datechange(val) {
      this.formData.ReleaseTime = formartDate(val, "all");
    }
  }
};
</script>

<style lang="scss" scoped>
.divContainer {
  background-color: #fff !important;
  padding: 24px 46px !important;
  padding-left: 0 !important;
  border-radius: 8px !important;
  .divContent {
    min-height: 30vh !important;
  }
  .devOperate {
    display: flex;
    justify-content: center;
  }
}
/deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}
/deep/ .el-form-item__content {
  line-height: 40px !important;
}
</style>
