<template>
  <div id="wangeditor">
    <div ref="editorElem" style="text-align:left" class="divEditorBorder"></div>
  </div>
</template>

<script>
import E from 'wangeditor'
import Cookies from 'js-cookie'
import axios from 'axios'
export default {
  name: 'RichText',
  components: {},
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    remarks: {
      type: String,
      default: ''
    },
    apiUrl: {
      type: Object,
      default() {
        return {
          baseApi: Cookies.get('Base-Api'),
          uploadSingle: '/File/UploadSingleFile'
        }
      }
    }
  }, // 接收父组件的方法
  data() {
    return {
      editor: null,
      editorContent: ''
    }
  },
  watch: {
    // value() {
    //   this.editor.txt.html(this.value);
    // }
  },
  mounted() {
    this.initialize()
    this.editor.txt.html(this.value)
  },
  methods: {
    initialize() {
      var editor = new E(this.$refs.editorElem)
      this.editor = editor
      this.editor.customConfig.zIndex = 100
      this.editor.customConfig.onchange = html => {
        this.editorContent = html
        this.$emit('input', this.editorContent, this.name)
      }
      // this.editor.customConfig.uploadImgShowBase64 = true // 使用 base64 保存图片
      this.editor.customConfig.uploadImgServer = this.apiUrl.baseApi + this.apiUrl.uploadSingle // '你的上传图片的接口'
      // this.editor.customConfig.uploadFileName = '你自定义的文件名'
      this.editor.customConfig.menus = [
        // 菜单配置
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'code', // 插入代码
        'undo', // 撤销
        'redo' // 重复
      ]
      this.editor.customConfig.uploadImgShowBase64 = true
      const that = this

      this.editor.customConfig.customUploadImg = async function(files, insert) {
        // files 是 input 中选中的文件列表
        // insert 是获取图片 url 后，插入到编辑器的方法
        const data = new FormData()
        data.append('typeOption', 'upload_image')
        files.forEach(f => {
          data.append('upload', f)
        })
        axios({
          url: that.apiUrl.baseApi + that.apiUrl.uploadSingle,
          method: 'post',
          data,
          headers: {
            'Authorization': Cookies.get('Admin-Token')
          },
          withCredentials: true // 此处可删掉，没发现有什么用
        })
          .then(res => {
            var resData = res.data.Result
            try {
              insert(resData.Url)
            } catch (err) {
              console.log(err)
            }
          })
          .catch(error => {
            console.log(error)
          })
        // 上传代码返回结果之后，将图片插入到编辑器中
        // insert(imgUrl)
      }
      // 下面是最重要的的方法
      // 限制一次最多上传 5 张图片
      editor.customConfig.uploadImgMaxLength = 1
      this.editor.customConfig.uploadImgHooks = {
        before: function(xhr, editor, files) {
          xhr.timeout = 30000
          // 图片上传之前触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件

          // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
          // return {
          //     prevent: true,
          //     msg: '放弃上传'
          // }
        },
        success: function(xhr, editor, result) {
          // 图片上传并返回结果，图片插入成功之后触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
          // this.imgUrl = Object.values(result.data).toString()
        },
        fail: function(xhr, editor, result) {
          // 图片上传并返回结果，但图片插入错误时触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
        },
        error: function(xhr, editor) {
          // 图片上传出错时触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
        },
        timeout: function(xhr, editor) {
          // 图片上传超时时触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
        },

        // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
        // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
        customInsert: function(insertImg, result, editor) {
          console.log(result)
          // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
          // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

          // // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
          // const url = Object.values(result.data) // result.data就是服务器返回的图片名字和链接
          // JSON.stringify(url) // 在这里转成JSON格式
          const url = result.Result.path
          insertImg(url)
          // result 必须是一个 JSON 格式字符串！！！否则报错
        }
      }
      this.editor.customConfig.uploadImgHeaders = {
        'Authorization': Cookies.get('Admin-Token')
      }

      this.editor.create() // 创建富文本实例
      // if (!this.value) {
      //   this.editor.txt.html('请编辑内容1')
      // }
    },
    getVal() {
      return this.editorContent
    }
  }
}
</script>

<style lang="scss" scoped>
.divEditorBox {
  width: 100%;
  // min-height: 300px;
  margin-top: 2px;
}
.divEditorBorder {
  width: 100%;
  //min-height: 500px;
  // border-right: 1px solid #c4c4c4;
  // border-left: 1px solid #c4c4c4;
  // border-bottom: 1px solid #c4c4c4;
  // margin-top: -2px;
}
</style>
<style lang="scss">
.w-e-text-container {
  height: 400px !important;
}
.w-e-text {
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: rgba(57, 70, 100, 0.4);
    // background: $themcolor;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: #ccc;
  }
}
</style>

